<template>
<v-card rounded="lg">
    <v-card-title class="">
        <h2 class="mb-0" style="width:100%; align-items:center;">
            {{ $t("conversion_tunnel") }}
            <v-tooltip right content-class="white--tooltip" max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="info" class="ml-2" v-bind="attrs" v-on="on">$circleinfo_d</v-icon>
                </template>
                <span>
                    <span><b>{{ $t('titleConversion') }}</b><br></span>
                    <span v-html="$t('descriptionConversion')"></span>
                </span> 
            </v-tooltip>
        </h2>
    </v-card-title>
    <v-card-text class="d-flex flex-column align-center">
        <div v-for="(nb, label, index) in stats" class="tunnelConversion" :style="'background-color: rgba(22, 20, 89, ' + (1 - (0.1 * index)) + '); width: ' + (95 - (10 * index)) + '%;'">
            {{ nb }} {{ $t(label) }}
        </div>
    </v-card-text>
</v-card>
</template>

<script>
export default {
    name: "detailsCanalAcquisitionComponent",
    props:['stats'],
    data() {
        return {}
    },
    created() {},
    mounted() {},
    watch: {},
    methods: {}
}
</script>

<style lang="scss">
    .tunnelConversion {
        padding: 10px;
        border-radius: 25px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin: 5px;
        color: white;
    }
</style>