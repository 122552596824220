<template>
    <v-container fluid class="pa-6 campagne" v-if="campaign">
        <v-row>
            <v-col cols="12" v-if="campaign && campaign.sousType == 'campVMSout' && campaign.status == 'DRAFT'">
                <!-- <v-alert border='left' :icon="'$warning_s'" type="warning" class="mb-0 mt-3">
                    <h6 style="color: white; padding-bottom: 0;" v-html="$t('VMSrulesText')"></h6>
                </v-alert> -->
                <v-alert class="mb-0" :icon="'$warning_s'" border="left" color="warning" text >
                    <b style="font-size:16px;">{{ $t("VMSrulesText") }}</b>
                </v-alert>
            </v-col>
            <v-col cols="12" v-if="$func.hasRight('retroplanning/configure')">
                <CampaignConfigurationComponent :campaign="campaign" @edit="getForm(editUrl)" />
            </v-col>

            <v-col cols="6" v-if="campaign.config && campaign.config.canal === 'TEL' && campaign.config.prestataire_type === 'CALL' && !campaign.diabolo_campaign.value">
                <v-card rounded="lg" class="mb-6">
                    <v-card-text class="text-center">
                        <v-alert class="mb-0" border="left" color="info" text>
                            {{ $t('noDiaboloCampaignSelected') }}
                            <v-select class="mt-6" hide-details dense solo flat :items="diaboloCampaigns" v-model="diaboloCampaignSelected" return-object></v-select>
                        </v-alert>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="6" v-else>
                <v-row class="" v-if='campaign.config && campaign.config.canal && (["TEL"]).includes(campaign.config.canal)'>
                    <v-col cols="6" v-if="$func.hasRight('retroplanning/get_objective')">
                        <rdvObjective :data="statCampCall" :key="'camp_call_'+statsCallKey" @editCallStats="getForm(objectiveUrl)"/>
                    </v-col>
                    <v-col cols="6">
                        <treatmentStats :data="statTreatment" :key="'camp_treatment_'+statsCallKey"/>
                    </v-col>
                    <v-col cols="6" v-if="campaign.config.prestataire_type === 'VENDORS'">
                        <campDonutComponent :title="'statsRdvVendor'" :data="statRdvVendor" :key="'camp_rdvVendor_'+statsCallKey"/>
                    </v-col>
                    <v-col cols="6">
                        <campDonutComponent :title="'statsFailMotif'" :data="statFailMotive" :key="'camp_fail_'+statsCallKey"/>
                    </v-col>
                </v-row>
                <NumberStatComponent :colNumber="6" :campaignPage='true' :values="stat" @openStats="openStats"  v-if='campaign.config && campaign.config.canal && !(["TEL"]).includes(campaign.config.canal)'/>
                <NumberStatComponent :colNumber="6" :campaignPage='true' :values="stat" @openStats="openStats"  v-if='campaign.type && campaign.type =="CAMP_IN"'/>
                <campaignStat v-if='campaign.config && campaign.config.canal && (["EMAIL", "SMS", "MMS", "VMS"]).includes(campaign.config.canal) && campaign.sousType !== "campSMSconversational"' :campaign="campaign" @openStats="openStats" class="mt-6"/>
                <campaignLinkStat v-if='campaign.config && campaign.config.canal && (["EMAIL", "SMS", "MMS", "VMS"]).includes(campaign.config.canal)' class="mt-6"/>               
                <campaignHeralStat v-if='campaign.config && campaign.config.canal && campaign.config.canal === "EMAIL"' :campaign="campaign" class="mt-6"/>
                <TunnelConversionComponent v-if="campaign.sousType === 'campSMSconversational'" :stats="statsTunnel" class="mt-6" />
            </v-col>

            <v-col cols="6">
                <CampaignTemplate v-if="campaign.id" :campaign="campaign" :key="'template_'+key"/>
                <OperationContactListComponent :key="'contactList_' + key" :operationId="$route.params.operation_id" v-if="campaign.type && campaign.type != 'CAMP_IN' && $route.params.operation_id" :sousType="campaign.sousType" :limitRetroId="$route.params.id" class="my-4 mt-6" @refreshContactList="refreshContactList" :loadData="true" />
                <CampaignContactListComponent :key="'contactList_' + key" v-else-if="campaign.type && campaign.type != 'CAMP_IN'" :sousType="campaign.sousType" :limitRetroId="$route.params.id" class="my-4 mt-6" @refreshContactList="refreshContactList" :loadData="true" />
                <CampaignBudgetCard v-if="$func.hasRight('retroplanning/displayprice') && (!packId || packId != 1) && campaign.sousType !== 'campSMSconversational'" class="mt-6"/>
            </v-col>

        </v-row>

        <dialogStatsContactComponent v-if="openDialogStats" @closeStatsContactDialog="openDialogStats = false" :openDialogStats="openDialogStats" :prefilter="prefilter" :id="this.$route.params.id" :idType="'retroplanning_id'" />

        <FormDialogComponent v-if="form" ref="componentForm" :type="'edit'" :form="form" :action="'SET_DATA'" />

        <dialogProgramRpComponent v-if="campaign.id" :openProgramRpDialog="openProgramRpDialog" :retroplanning="campaign" @refresh="refresh" @close="openProgramRpDialog = false"/>
    </v-container>
</template>

<script>
import CampaignConfigurationComponent from '@/modules/campagnes/views/CampaignConfigurationComponent'
import campaignStat from '@/components/dashboardComponent/campaignStat'
import campaignHeralStat from '@/components/dashboardComponent/campaignHeralStat'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import NumberStatComponent from '@/components/dashboardComponent/NumberStatComponent'
import refreshData from '@/mixins/mixins';
import getForm from '@/mixins/mixins'
import GenericDataService from '@/services/GenericDataService'
import CampaignTemplate from '../CampaignTemplate.vue';
import dateManipulation from '@/mixins/dateManipulation'
import dialogStatsContactComponent from '@/components/dialogs/dialogStatsContactComponent'
import OperationContactListComponent from '@/modules/operations/operationsComponents/contactListComponent'
import CampaignContactListComponent from '@/modules/campagnes/campagnesComponents/contactListComponent'
import campaignLinkStat from '@/components/dashboardComponent/campaignLinkStat'
import CampaignBudgetCard from '../CampaignBudgetCard.vue';
import dialogProgramRpComponent from '@/components/dialogs/dialogProgramRpComponent.vue';
import rdvObjective from '@/modules/operations/operationsComponents/rdvObjective.vue';
import treatmentStats from '@/modules/operations/operationsComponents/treatmentStats.vue';
import campDonutComponent from '@/modules/operations/operationsComponents/campDonutComponent.vue';
import TunnelConversionComponent from '@/modules/missions/missionsComponents/acquisitionComponents/tunnelConversionComponent.vue';


export default {
    name:"CampaignOutgoingDashboard",
    components: {
        FormDialogComponent,
        NumberStatComponent,
        CampaignConfigurationComponent,
        dialogStatsContactComponent,
        campaignStat,
        campaignHeralStat,
        campaignLinkStat,
        OperationContactListComponent,
        CampaignContactListComponent,
        CampaignTemplate,
        CampaignBudgetCard,
        dialogProgramRpComponent,
        rdvObjective,
        treatmentStats,
        campDonutComponent,
        TunnelConversionComponent
    },
    mixins:[refreshData, dateManipulation, getForm],
    data() {
        return {
            stat: {
                title: "fewNumbersTitle",
                data: []
            },
            statCampCall: {},
            statTreatment: {},
            statRdvVendor: {},
            statFailMotive: {},
            template:{},
            form: null,
            apiUrl: "/retroplanning/get?id=" + this.$route.params.id,
            mutation: "campaign/setCampaignData",
            editUrl: "/operation/getFormRealRetroplanning?id=" + this.$route.params.id,
            objectiveUrl : "/retroplanning/"+this.$route.params.id+"/getForm?formName=rdv_objective",
            openDialogStats:false,
            prefilter: false,
            key:0,
            statsCallKey:0,
            statsCampIn: {},
            entrypoint: {},
            diaboloCampaigns: [],
            diaboloCampaignSelected: null,
            statsTunnel: {},
        }
    },
    destroyed() {
        this.$store.commit("campaign/removeSingleCampaignData");
    },
    mounted() {
        this.refreshData();
    },
    watch: {
        campaign(newVal){
            if(newVal && newVal.type == 'CAMP_CALL') {
                if(newVal.sousType == 'campCALL') {
                    GenericDataService.getData('/retroplanning/getCampCallStats?retroplanning_id=' + this.$route.params.id ).then((response) => {
                        this.statCampCall = response.data.data.statsRdv;
                        this.statTreatment = response.data.data.statsTreatment;
                        this.statRdvVendor = response.data.data.statsRdvVendor;
                        this.statFailMotive = response.data.data.statsMotifFail;
                        this.statsCallKey++;
                    });
                }

                if(newVal.sousType == 'campSMSconversational') {
                    GenericDataService.getData('/retroplanning/getTunnelStats?retroplanning_id=' + this.$route.params.id).then((response) => {
                        this.statsTunnel = response.data.data;
                    });
                }
            }
            
            GenericDataService.getData('/retroplanning/' + this.$route.params.id + '/getStats').then((response) => {
                this.stat.data = response.data.data;
            });

            if(this.campaign.config.prestataire_type && this.campaign.config.prestataire_type === "CALL") {
                GenericDataService.getData('/retroplanning/' + this.$route.params.id + '/getDiaboloCampaigns').then((response) => {
                    this.diaboloCampaigns = response.data.data;
                });
            }

            if(this.campaign.template_id && this.campaign.template_id.id){
                GenericDataService.getData('/template/get?id='+this.campaign.template_id.id).then((response) => {
                    if(response.data.data){
                        this.template = response.data.data
                    }
                })
            }

            if(newVal.actions && newVal.actions.get) {
                newVal.actions.get = JSON.parse(JSON.stringify(newVal.actions.get).replaceAll('{{id}}', this.campaign.id));
                newVal.actions.get.forEach((element, index, object) => {
                    if(element.menus && element.menus[0]){
                        element.menus[0].forEach(elementMenu => {
                            if(elementMenu.check && !newVal.config[elementMenu.check]){
                                elementMenu.disabled = true
                            }
                        });
                    }

                    if(element.name && element.name === "OperationDetail" && !newVal.operation_id) {
                        object.splice(index, 1);
                    }
                });
                this.$store.dispatch("base/SET_HEADER_ACTIONS", newVal.actions.get);
            }

            // this.key++;
        },
        diaboloCampaignSelected(newVal) {
            GenericDataService.postData('/retroplanning/' + this.$route.params.id + '/setDiaboloCampaign', newVal).then((response) => {
                this.refreshData();
            });
        }
    },
    computed: {
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
        campaign(){
            return this.$store.state.campaign.campaign
        },
        openProgramRpDialog: {
            get: function(){
                return this.$store.state.campaign.programRpDialog;
            },
            set: function(val){
                this.$store.dispatch("campaign/SET_PROGRAM_RP_DIALOG", false);
            }
        },
    },
    methods: {
        refreshContactList(){
            this.refreshData();
        },
        openStats(item){
            this.prefilter = item.prefilter ? item.prefilter : item.label;
            this.openDialogStats = true;
        },
        refresh(){
            this.$store.dispatch("campaign/SET_PROGRAM_RP_DIALOG", false);
            this.refreshData();
        }
    }
}
</script>

<style lang="scss">
    .campagne {
        .todo-title {
            display:flex;
            h3 {
                line-height: 18px;
            }
            p {
                margin-bottom:0;
                font-weight: bold;
                text-decoration: underline;
                color: var(--v-primary-base) !important;
            }
        }
        .v-card__title {
            display:flex;
            justify-content: space-between;
            align-items:flex-start;
            .cardTitle {
                display:flex;
                justify-content:center;
                align-items:center;
                .title {
                    margin-left:10px;
                    p {
                        margin-bottom:0;
                        font-weight:bold;
                        line-height: 12px;
                    }
                    h3 {
                        margin-top:5px;
                        line-height: 20px;
                    }
                }
            }
            .numberTitle {
                display:flex;
                align-items: flex-end;
                justify-content: flex-start;
                font-weight:bold;
                line-height: normal;
                .big {
                    font-size: 2.5em;
                    line-height:40px;
                }
            }
        }
    }
</style>
